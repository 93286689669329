import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { rhythm } from "../../utils/typography"

const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="How much will my film cost? - Services" />
      <article style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <header>
          <h1>How much will my film cost?</h1>
        </header>

        <Image
          fluid={data.mast.childImageSharp.fluid}
          alt="Some of my clients"
          style={{
            width: `360px`,
            maxWidth: `100%`,
          }}
        />

        <p>
          When budgeting for your video project it’s worth having a think of the
          various elements that could affect costs early on to avoid any
          surprises at a later stage. This is all part of the briefing process
          but here are a few considerations to factor in when planning a
          project:
        </p>

        <h2>Equipment</h2>
        <p>
          This initial thought process is really important to make sure that all
          objectives are met and your film is aligned with your brand’s values.
          In other words, a certain type of video might work better to achieve
          your goal than another and together we find the best solution for your
          project. Budget will also be a consideration when looking at different
          options - saying that I will always provide you with the best possible
          production value for your budget.
        </p>

        <h2>Editing</h2>
        <p>
          At this stage a talented editor will bring out raw emotions in the
          viewer dependent on the desires and creativity of the project brief.
          The editing process is a massive challenge as you have to balance many
          areas while keeping on topic and creative. Experience counts at this
          stage and likewise post production can add that extra sheen on to a
          video that can mean the difference between average and a polished end
          product.
        </p>

        <h2>Location</h2>
        <p>
          Is your shoot going to be on your business premises or does your video
          require hiring a studio or location? If several locations feature in
          one film it’s critical to plan in enough time to get from one location
          to another. This can of course affect the time for the actual filming
          so a less stressful approach for both contributors and crew would be
          to split a shoot over two or more days, which of course will also
          incur higher costs
        </p>

        <h2>Getting the message right</h2>
        <p>
          Good video production is the marriage of powerful and well thought out
          images (moving or still) and a powerful narrative. The message to your
          audience is key and a more in-depth video will require more pre and
          post-production. This work is integral to setting the foundations of
          an effective video. The content determines whether or not it will
          ultimately be successful in its chosen application. This is why
          research, content development, interview preparation and script
          writing are so important.
        </p>

        <h2>Music</h2>
        <p>
          Whether you are after some nice pads to sit below your film, a massive
          dance tune or a sombre classical piece, audio is highly important to
          any video! Many would argue more than the shots themselves, and this
          all costs money. Sound editing and music can roughly be priced at £100
          per 3mins of video. So have a think about what style of music you
          would like and I will make sure to get the right tracks ready for you
          on the day of the edit.
        </p>
        <p>
          Depending on the type of video there are also other things to consider
          like sound effects, voice over, on-screen text or maybe even motion
          graphics. Again, this is something that we can discuss in more detail
          in the briefing stage of the project.
        </p>

        <div className="tight">
          <h2>WANT TO DISCUSS DIFFERENT OPTIONS FOR YOUR BUDGET?</h2>
          <h3>SEND ME AN EMAIL AND WE’LL ARRANGE A CHAT</h3>
        </div>
      </article>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    mast: file(absolutePath: { regex: "/video-cost.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
